import { jwtDecode } from 'jwt-decode';
import { Store } from 'vuex';
import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { defineMessages } from '@/lib/i18n';
import { generalApi } from '@/lib/api/general';

const m = defineMessages({
  tokenExpired:
    'トークンの有効期限が切れています、再度パスワード再発行の手続きを行ってください',
  invalidToken:
    'トークンが無効です。再度パスワード再発行の手続きを行ってください。',
  missingToken:
    '必要なトークンがセットされていません。再度パスワードの再発行を行ってください。',
});

interface TokenValidationResult {
  isValid: boolean;
  token: string | null;
}

export const checkToken = async (
  token: string | undefined,
  store: Store<State>
): Promise<TokenValidationResult> => {
  if (!token) {
    commitAddNotification(store, {
      messageKey: m.missingToken,
      color: 'error',
    });
    return { isValid: false, token: null };
  }

  try {
    const decoded: { exp: number } = jwtDecode(token);
    const nowInSeconds = Math.floor(Date.now() / 1000);

    if (decoded.exp < nowInSeconds) {
      commitAddNotification(store, {
        messageKey: m.tokenExpired,
        color: 'error',
      });
      return { isValid: false, token: null };
    }

    await generalApi.verifyToken(token);
  } catch (e) {
    commitAddNotification(store, {
      messageKey: m.invalidToken,
      color: 'error',
    });
    return { isValid: false, token: null };
  }

  return { isValid: true, token };
};
