import { ref } from 'vue';
import { store } from '@/store/index';
import router from '@/router';

import { dispatchSignUp } from '@/store/main/actionsMain';
import { readUserLocale } from '@/store/main/getters';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSignUpComposable = (): any => {
  const isValidForm = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const firstName = ref<string>('');
  const lastName = ref<string>('');
  const middleName = ref<string>('');
  const phone = ref<string>('');
  const department = ref<string>('');
  const position = ref<string>('');
  const companyName = ref<string>('');
  const email = ref<string>('');
  const password = ref<string>('');
  const recaptchaToken = ref('');

  const submit = async (): Promise<void> => {
    isLoading.value = true;
    try {
      const data = {
        first_name: firstName.value,
        last_name: lastName.value,
        middle_name: middleName.value,
        email: email.value,
        phone: phone.value,
        department: department.value,
        position: position.value,
        password: password.value,
        company_name: companyName.value,
        g_recaptcha_response: recaptchaToken.value,
        locale: readUserLocale(store),
      };
      await dispatchSignUp(store, data);
      await router.push({ name: 'signUpComplete' });
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isValidForm,
    isLoading,
    firstName,
    lastName,
    middleName,
    phone,
    department,
    position,
    companyName,
    email,
    password,
    recaptchaToken,
    submit,
  };
};
